import React, { useState, useEffect } from "react";
import MloCard from '../components/MloCard.js'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

var result = []
function Team() {
  const [LoData, setLoData] = useState([])
  var links = [
    { endpoint: 'https://swmc-landingpages.s3.amazonaws.com/mortgagepossible.com/includes/lodata.json'},
    { endpoint: 'https://swmc-landingpages.s3.amazonaws.com/swmcretail.com/includes/lodata.json'},
    { endpoint: 'https://swmc-landingpages.s3.amazonaws.com/lowrates.com/includes/lodata.json'},
    { endpoint: 'https://swmc-landingpages.s3.amazonaws.com/sunwestmortgage.com/includes/lodata.json'},
    { endpoint: 'https://swmc-landingpages.s3.amazonaws.com/lowratespr.com/includes/lodata.json'}
  ]

  useEffect(() => {
    links.forEach(function (link){
      fetch(link.endpoint)
        .then(function (response) {
            return response.json();
        })
        .then(
          (data) => {
          result = result.concat(data)

          setLoData(result)
          })
        .catch(function (error) {
          console.log("Error:", error);
        });
      });
    },[]);
  // render() {
    // const {LoData} = this.state;
    // console.log('*****result**resultresultresult************\n', LoData)
    const loComponents = LoData.map(lodata => <MloCard key={lodata.loID} lodata ={lodata} />)
    return (
      <div id="team-wrapper">
        <Container className="mt-5 pt-5">
          <Row>
            <Col lg={12} className="text-center mt-4 pt-5">
              <br />
              <h1>Our Team Of Experts</h1>
              <p>We invite you to meet our team of experts and navigate through them to learn more.</p>
            </Col>
            <Container>
              <Row>
                {loComponents}
                {/* <MloCard lodata ={LoData} /> */}
              </Row>
            </Container>
            <Col className="text-center m-4 pt-3">
							<Image className="img-fluid" src="./assest/NewSunWestLogo-1.png" alt="NewSunWestLogo-1" style={{width:"200px"}} />
            </Col>
          </Row>
        </Container>
      </div>
    );
  // }
}
export default Team