import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import { Link, withRouter } from 'react-router-dom';
// import { genericHashLink } from 'react-router-hash-link';
import './SubFooter.css';

import { FaFacebookSquare } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";

// const MyHashLink = (props) => genericHashLink(props, Link);

function SubFooter() {
    return (
        <div>
             <section className="sub-footer text-center box-shadow">
                <Container className="p-4">
                    <Row>
                        <Col  ms={12} md={4}>
                            <h3>About Us</h3>
                            <div className="m-3">
                                <p>At Sun West Mortgage Company, Inc (NMLS ID 3277), we dedicate ourselves to offering the best service to our customers.</p>
                            </div>
                        </Col>
                        <Col  ms={12} md={4}>
                            <h3>CONTACT US</h3>
                            <div className="m-3">
                                <p>6131 Orangethorpe Ave #500, Buena Park, CA 90620<br/>
                                Phone: <a href="tel:(844) 765-6844" target="_blank" rel="noopener noreferrer">(844) 765-6844</a></p>
                            </div>
                        </Col>
                        <Col  ms={12} md={4}>
                            <h3>RESOURCES</h3>
                            <div className="m-3">
                                <p><a href="https://swmcretail.com/home-buyers-guide/">Home Buyer&#39;s Guide</a><br /><a href="https://swmcretail.com/refinancing-guide/">Refinancing Guide</a><br /><a href="https://seemyloanstatus.com/ApplyNow/?c=sl" target="_blank" rel="noopener noreferrer">Apply Now</a></p>
                            </div>
                        </Col>                        
                    </Row>
                    <Row className=" pt-4">
                        <Col className="text-sm-center text-md-right pl-3" sm={12} md={{ span: 3, offset: 2 }}>
                            <h3>
                                <span className="fl-heading-text">GET SOCIAL</span>
                            </h3>
                        </Col>
                        <Col className="text-sm-center text-md-left p-3 p-sm-3 p-md-0" sm={12} md={3}>
                            <span className="p-2">
                                <link href="https://swmcretail.com/" />
                                <a href="https://www.facebook.com/SunWestMortgage" target="_blank" rel="noopener noreferrer" title="Facebook" aria-label="Facebook" role="button">
                                    <FaFacebookSquare style={{fontSize:"30px"}} />
                                </a>
                            </span>
                            <span className="p-2">
                                <link href="https://swmcretail.com/" />
                                <a href="https://twitter.com/SunWestMortgage" target="_blank" rel="noopener noreferrer" title="Twitter" aria-label="Twitter" role="button">
                                <FaTwitter style={{fontSize:"30px"}} />
                                </a>
                            </span>
                            <span className="p-2">
                                <link href="https://swmcretail.com/" />
                                <a href="https://www.linkedin.com/company/sun-west-mortgage-company/about/" target="_blank" rel="noopener noreferrer" title="LinkedIn" aria-label="LinkedIn" role="button">
                                <FaLinkedin style={{fontSize:"30px"}} />
                                </a>
                            </span>
                        </Col>
                        
                        
                        <Col sm={12} md={4}>
                            <h3>
                                NMLS : #3277
                            </h3>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default SubFooter;