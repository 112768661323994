import React from 'react';
import Navigationbar from './components/NavDesktop';
import Footer from './components/Footer';
import {TinyButton as ScrollUpButton} from 'react-scroll-up-button';

import Team from './pages/Team';


function App() {
  return (
    <React.Fragment>
      <Navigationbar />
        <Team />
      <ScrollUpButton
        AnimationDuration={1000}
        ContainerClassName="scrollToTop"
        style={{fill:"white"}} />
      <Footer />
    </React.Fragment>
  );
}

export default App;
