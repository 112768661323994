import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Footer.css';
import SubFooter from '../components/SubFooter';


export default function Footer() {
    return (
        <div>
            <SubFooter />
            <footer className="text-center main-footer p-4">
                <p>&copy;{new Date().getFullYear()}&nbsp;Sun West Mortgage Company, Inc. All Rights Reserved <br />
                    <strong>Please <a href="disclaimer.php">Click Here</a> or refer to&nbsp;<a href="http://www.nmlsconsumeraccess.org/" target="_blank" rel="noopener noreferrer">www.nmlsconsumeraccess.org&nbsp;</a>to see where Sun West Mortgage Company, Inc. (NMLS ID 3277) is a licensed lender and servicer.</strong> 
                    We are not accepting applications from Georgia or Massachusetts residents or purchasing mortgages secured by&nbsp; Georgia and Massachusetts properties at this time. Please&nbsp;<a href="https://www.swmc.com/TXdis.php" target="_blank" rel="noopener noreferrer">Click Here&nbsp;</a>to view Texas Complaint Notice and Servicing Disclosure.
                </p>
                
                <p>
                    <strong>Although Sun West Mortgage is approved to conduct business in the state of New York, this website has not yet been approved by the State of New York Department of Financial Services. For properties located in the State of New York, this website can not be used to upload an application, please visit <a target="_blank" rel="noopener noreferrer" href="https://www.swmc.com">SWMC.com</a> to upload an application.</strong>
                </p><br />
                <Container>
                    <Row className="justify-content-md-center">
                        <Col lg={4}>
                            <img id="equalImg" alt="EHO logo" src="./assest/eho_blue.png" title="Equal Housing Opportunity" style={{maxWidth: "100%x"}} />
                        </Col>
                        {/* <Col lg={4}>
                            <embed src="https://www.checkbca.org/CompanyWidget.aspx?ID=59000209&amp;WidgetType=1" style={{width: "136px"}} />
                        </Col> */}
                    </Row>
                </Container>
            </footer>
        </div>
        

    )
}




