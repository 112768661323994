import React from 'react';
import Image from 'react-bootstrap/Image';

import './Navbar.css';


const NavDesktop = () =>  {
        return (
            <div> 
                <header className="fixed-top teamNavbar fl-builder-content fl-builder-content-12 fl-builder-global-templates-locked" data-post-id="12" itemScope="itemScope" itemType="http://schema.org/WPHeader" data-type="header" data-sticky="0" data-shrink="0" data-overlay="0" data-overlay-bg="transparent">
                <div className="fl-row fl-row-full-width fl-row-bg-color fl-node-5bdc292c5794a" data-node="5bdc292c5794a">
                    <div className="fl-row-content-wrap">
                        <div className="uabb-row-separator uabb-top-row-separator">
                        </div>
                        <div className="fl-row-content fl-row-full-width fl-node-content">
                            <div className="fl-col-group fl-node-5bdc295cdc1ea fl-col-group-equal-height fl-col-group-align-center" data-node="5bdc295cdc1ea">
                                <div className="fl-col fl-node-5bdc295cdc3a7 fl-col-small" data-node="5bdc295cdc3a7">
                                    <div className="fl-col-content fl-node-content">
                                        <div id="main-logo" className="fl-module fl-module-pp-image fl-node-5bdc29b03d6e0" data-node="5bdc29b03d6e0">
                                            <div className="fl-module-content fl-node-content">
                                                <div className="pp-photo-container">
                                                    <div className="pp-photo pp-photo-align-left pp-photo-align-responsive-center" itemScope itemType="http://schema.org/ImageObject">
                                                        <div className="pp-photo-content ">
                                                            <div className="pp-photo-content-inner">
                                                                <a href="https://swmcretail.com/" target="_blank" rel="noopener noreferrer" itemProp="url">
                                                                <Image className="img-fluid" src="./assest/NewSunWestLogo-1.png" alt="NewSunWestLogo-1" style={{width:"200px"}} />
                                                                    <div className="pp-overlay-bg"></div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="fl-col fl-node-5bdc295cdc427 fl-col-small" data-node="5bdc295cdc427">
                                    <div className="fl-col-content fl-node-content">
                                        <div id="apply-btn" className="fl-module fl-module-pp-smart-button fl-node-5bdc29652086c" data-node="5bdc29652086c">
                                            <div className="fl-module-content fl-node-content">
                                                <div className="pp-button-wrap pp-button-width-auto pp-button-right">
                                                    <a href="https://seemyloanstatus.com/ApplyNow/?c=sl" target="_blank" rel="noopener noreferrer" className="pp-button" role="button">
                                                        <span className="pp-button-text">APPLY TODAY</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="header-menu-row" className="fl-module fl-module-html fl-node-5bdc2b198ad94" data-node="5bdc2b198ad94">
                                            <div className="fl-module-content fl-node-content">
                                                <div className="fl-html">
                                                    <div className="quote-menu-row"><a className="free-quote-txt" href="https://swmcretail.com/contact-us/">Free Rate Quote</a> | <button id="responsive-menu-button" className="responsive-menu-button responsive-menu-boring
            responsive-menu-accessible" type="button" aria-label="Menu">

                                                            <span className="responsive-menu-label responsive-menu-label-left">
                                                                <span className="responsive-menu-button-text">Menu</span>
                                                            </span>

                                                            <span className="responsive-menu-box">
                                                                <span className="responsive-menu-inner"></span>
                                                            </span>
                                                        </button>
                                                        <div id="responsive-menu-container" className="push-top">
                                                                <div id="responsive-menu-wrapper">
                                                                <ul id="responsive-menu" className="">
                                                                    <li id="responsive-menu-item-26" className=" menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-10 current_page_item responsive-menu-item responsive-menu-current-item"><a href="https://swmcretail.com/" className="responsive-menu-item-link">Home</a></li>
                                                                    <li id="responsive-menu-item-27" className=" menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children responsive-menu-item responsive-menu-item-has-children"><a href="#" className="responsive-menu-item-link">About Us<div className="responsive-menu-subarrow"> +</div></a>
                                                                        <ul className='responsive-menu-submenu responsive-menu-submenu-depth-1'>
                                                                            <li id="responsive-menu-item-964" className=" menu-item menu-item-type-post_type menu-item-object-page responsive-menu-item"><a href="https://swmcretail.com/our-story/" className="responsive-menu-item-link">Our Story</a></li>
                                                                            <li id="responsive-menu-item-955" className=" menu-item menu-item-type-post_type menu-item-object-page responsive-menu-item"><a href="https://swmcretail.com/our-mortgage-process/" className="responsive-menu-item-link">Our Mortgage Process</a></li>
                                                                            <li id="responsive-menu-item-1879" className=" menu-item menu-item-type-post_type menu-item-object-page responsive-menu-item"><a href="https://swmcretail.com/leadership/" className="responsive-menu-item-link">Leadership</a></li>
                                                                            <li id="responsive-menu-item-971" className=" menu-item menu-item-type-post_type menu-item-object-page responsive-menu-item"><a href="https://swmcretail.com/careers/" className="responsive-menu-item-link">Careers</a></li>
                                                                            <li id="responsive-menu-item-954" className=" menu-item menu-item-type-post_type menu-item-object-page responsive-menu-item"><a href="https://swmcretail.com/real-coaching-platform/" className="responsive-menu-item-link">Real Coaching Platform</a></li>
                                                                        </ul>
                                                                    </li>
                                                                    <li id="responsive-menu-item-30" className=" menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children responsive-menu-item responsive-menu-item-has-children"><a href="#" className="responsive-menu-item-link">Loan Services<div className="responsive-menu-subarrow"> +</div></a>
                                                                        <ul className='responsive-menu-submenu responsive-menu-submenu-depth-1'>
                                                                            <li id="responsive-menu-item-961" className=" menu-item menu-item-type-post_type menu-item-object-page responsive-menu-item"><a href="https://swmcretail.com/conventional-loans/" className="responsive-menu-item-link">Conventional Loans</a></li>
                                                                            <li id="responsive-menu-item-959" className=" menu-item menu-item-type-post_type menu-item-object-page responsive-menu-item"><a href="https://swmcretail.com/fha-loans/" className="responsive-menu-item-link">FHA Loans</a></li>
                                                                            <li id="responsive-menu-item-957" className=" menu-item menu-item-type-post_type menu-item-object-page responsive-menu-item"><a href="https://swmcretail.com/investment-property-loans/" className="responsive-menu-item-link">Investment Property Loans</a></li>
                                                                            <li id="responsive-menu-item-956" className=" menu-item menu-item-type-post_type menu-item-object-page responsive-menu-item"><a href="https://swmcretail.com/jumbo-loans/" className="responsive-menu-item-link">Jumbo Loans</a></li>
                                                                            <li id="responsive-menu-item-953" className=" menu-item menu-item-type-post_type menu-item-object-page responsive-menu-item"><a href="https://swmcretail.com/refinancing/" className="responsive-menu-item-link">Refinancing</a></li>
                                                                            <li id="responsive-menu-item-951" className=" menu-item menu-item-type-post_type menu-item-object-page responsive-menu-item"><a href="https://swmcretail.com/renovation-construction-loans/" className="responsive-menu-item-link">Renovation/Construction Loans</a></li>
                                                                            <li id="responsive-menu-item-950" className=" menu-item menu-item-type-post_type menu-item-object-page responsive-menu-item"><a href="https://swmcretail.com/reverse-mortgage-hecm/" className="responsive-menu-item-link">Reverse Mortgage / HECM</a></li>
                                                                            <li id="responsive-menu-item-949" className=" menu-item menu-item-type-post_type menu-item-object-page responsive-menu-item"><a href="https://swmcretail.com/va-loans/" className="responsive-menu-item-link">VA Loans</a></li>
                                                                        </ul>
                                                                    </li>
                                                                    <li id="responsive-menu-item-967" className=" menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children responsive-menu-item responsive-menu-item-has-children"><a href="resources/index.html" className="responsive-menu-item-link">Resources<div className="responsive-menu-subarrow"> +</div></a>
                                                                        <ul className='responsive-menu-submenu responsive-menu-submenu-depth-1'>
                                                                            <li id="responsive-menu-item-970" className=" menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children responsive-menu-item responsive-menu-item-has-children"><a href="https://swmcretail.com/getting-started/" className="responsive-menu-item-link">Getting Started<div className="responsive-menu-subarrow"> +</div></a>
                                                                                <ul className='responsive-menu-submenu responsive-menu-submenu-depth-2'>
                                                                                    <li id="responsive-menu-item-958" className=" menu-item menu-item-type-post_type menu-item-object-page responsive-menu-item"><a href="https://swmcretail.com/home-buyers-guide/" className="responsive-menu-item-link">Home Buyer&#8217;s Guide</a></li>
                                                                                    <li id="responsive-menu-item-34" className=" menu-item menu-item-type-custom menu-item-object-custom responsive-menu-item"><a target="_blank" rel="noopener noreferrer" href="https://www.swmc.com/loan-calculators-mortgage-calculators.php" className="responsive-menu-item-link">Calculators</a></li>
                                                                                    <li id="responsive-menu-item-952" className=" menu-item menu-item-type-post_type menu-item-object-page responsive-menu-item"><a href="https://swmcretail.com/refinancing-guide/" className="responsive-menu-item-link">Refinancing Guide</a></li>
                                                                                </ul>
                                                                            </li>
                                                                        </ul>
                                                                    </li>
                                                                    <li id="responsive-menu-item-26" className=" menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-10 current_page_item responsive-menu-item responsive-menu-current-item"><a href="index.php" className="responsive-menu-item-link">Team</a></li>
                                                                    <li id="responsive-menu-item-36" className=" menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children responsive-menu-item responsive-menu-item-has-children"><a href="#" className="responsive-menu-item-link">Contact<div className="responsive-menu-subarrow"> +</div></a>
                                                                        <ul className='responsive-menu-submenu responsive-menu-submenu-depth-1'>
                                                                            <li id="responsive-menu-item-963" className=" menu-item menu-item-type-post_type menu-item-object-page responsive-menu-item"><a href="https://swmcretail.com/client-help/" className="responsive-menu-item-link">Client Help</a></li>
                                                                            <li id="responsive-menu-item-962" className=" menu-item menu-item-type-post_type menu-item-object-page responsive-menu-item"><a href="https://swmcretail.com/contact-us/" className="responsive-menu-item-link">Contact Us</a></li>
                                                                            <li id="responsive-menu-item-960" className=" menu-item menu-item-type-post_type menu-item-object-page responsive-menu-item"><a href="https://swmcretail.com/faq/" className="responsive-menu-item-link">FAQ</a></li>
                                                                        </ul>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>      
        </div>
        )
    }


export default NavDesktop;